/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Dealer
 */
export interface Dealer {
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    streetName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    click?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    clickPrice?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    lead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    postmaster?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    postmasterPro?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    leadBooster?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    leadBoosterPremium?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    leadBoosterPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    leadBoosterPremiumPrice?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    bilInfo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    searchagent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    reciveNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    reciveStatNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    receiveFavoriteNotification?: boolean;
}

/**
 * Check if a given object implements the Dealer interface.
 */
export function instanceOfDealer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DealerFromJSON(json: any): Dealer {
    return DealerFromJSONTyped(json, false);
}

export function DealerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dealer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'click': !exists(json, 'click') ? undefined : json['click'],
        'clickPrice': !exists(json, 'clickPrice') ? undefined : json['clickPrice'],
        'lead': !exists(json, 'lead') ? undefined : json['lead'],
        'postmaster': !exists(json, 'postmaster') ? undefined : json['postmaster'],
        'postmasterPro': !exists(json, 'postmasterPro') ? undefined : json['postmasterPro'],
        'leadBooster': !exists(json, 'leadBooster') ? undefined : json['leadBooster'],
        'leadBoosterPremium': !exists(json, 'leadBoosterPremium') ? undefined : json['leadBoosterPremium'],
        'leadBoosterPrice': !exists(json, 'leadBoosterPrice') ? undefined : json['leadBoosterPrice'],
        'leadBoosterPremiumPrice': !exists(json, 'leadBoosterPremiumPrice') ? undefined : json['leadBoosterPremiumPrice'],
        'bilInfo': !exists(json, 'bilInfo') ? undefined : json['bilInfo'],
        'searchagent': !exists(json, 'searchagent') ? undefined : json['searchagent'],
        'reciveNotifications': !exists(json, 'reciveNotifications') ? undefined : json['reciveNotifications'],
        'reciveStatNotifications': !exists(json, 'reciveStatNotifications') ? undefined : json['reciveStatNotifications'],
        'receiveFavoriteNotification': !exists(json, 'receiveFavoriteNotification') ? undefined : json['receiveFavoriteNotification'],
    };
}

export function DealerToJSON(value?: Dealer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'city': value.city,
        'streetName': value.streetName,
        'click': value.click,
        'clickPrice': value.clickPrice,
        'lead': value.lead,
        'postmaster': value.postmaster,
        'postmasterPro': value.postmasterPro,
        'leadBooster': value.leadBooster,
        'leadBoosterPremium': value.leadBoosterPremium,
        'leadBoosterPrice': value.leadBoosterPrice,
        'leadBoosterPremiumPrice': value.leadBoosterPremiumPrice,
        'bilInfo': value.bilInfo,
        'searchagent': value.searchagent,
        'reciveNotifications': value.reciveNotifications,
        'reciveStatNotifications': value.reciveStatNotifications,
        'receiveFavoriteNotification': value.receiveFavoriteNotification,
    };
}

